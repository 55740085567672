import React from "react";

export default function ITServicesMobileLogoSvg() {
  return (
    <svg
      width="24"
      height="37"
      viewBox="0 0 24 37"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M11.9754 11.9964C11.9621 11.6852 11.9375 11.3739 11.9375 11.0646C11.9375 7.64841 11.9242 4.21896 11.947 0.795207C11.9564 0.189787 11.7667 0 11.1498 0C7.72609 0.0278354 4.30297 0.0278354 0.880483 0C0.201046 0 -0.000127942 0.20497 0.0131571 0.874917C0.043523 4.23035 0.0264422 7.59147 0.0283401 10.9431C0.0283401 11.8693 0.02834 11.8731 0.933623 11.875H10.9354C11.2892 11.8905 11.6421 11.9241 11.9925 11.9755C11.7799 12.2299 11.5845 12.5013 11.351 12.7347C7.81719 16.2774 4.2783 19.808 0.734347 23.3267C0.492003 23.5476 0.301507 23.8194 0.176499 24.1226C0.0514908 24.4257 -0.00493587 24.7528 0.0112593 25.0803C0.0473188 28.4149 0.0264422 31.7532 0.0302379 35.0821C0.0302379 35.3649 0.0643996 35.6514 0.0852761 35.9665C0.362365 35.9779 0.58062 35.9969 0.798874 35.9969C4.2435 35.9969 7.69003 35.9779 11.1366 36.0101C11.7932 36.0101 11.9545 35.8204 11.9488 35.1846C11.9204 31.7684 11.9356 28.339 11.9375 24.9171C11.9375 24.0194 11.9716 23.9853 12.8769 23.9853C16.2931 23.9853 19.7244 23.9644 23.1463 23.9985C23.8371 23.9985 24.0041 23.786 23.9984 23.1274C23.968 19.7435 23.9851 16.3691 23.9851 12.9909C23.9851 12.1122 23.9851 12.1065 23.0931 12.1065H12.8902C12.5834 12.087 12.278 12.0503 11.9754 11.9964Z"
        fill="url(#paint0_linear_833_17)"
      />
      <path
        d="M11.9751 11.9985C12.2783 12.0519 12.5844 12.0874 12.8918 12.1048H23.0947C23.9829 12.1048 23.9848 12.1048 23.9867 12.9911C23.9867 16.3731 23.9696 19.7475 24 23.1257C24 23.7843 23.8387 24.0063 23.1478 23.9987C19.7317 23.9627 16.3022 23.9817 12.8785 23.9836C11.9694 23.9836 11.9295 24.0177 11.9295 24.9154C11.9295 28.3316 11.9143 31.761 11.9409 35.1848C11.9409 35.8168 11.7815 36.0141 11.1286 36.0084C7.6821 35.9781 4.23747 35.9971 0.790945 35.9971C0.57269 35.9971 0.354435 35.9781 0.0773467 35.9648C0.0564702 35.646 0.0242064 35.3632 0.0223086 35.0823C0.0223086 31.7477 0.0393894 28.4113 0.00332989 25.0805C-0.0132649 24.7529 0.0429789 24.4258 0.168012 24.1225C0.293045 23.8193 0.483757 23.5476 0.726418 23.3269C4.29061 19.8158 7.83267 16.2871 11.3526 12.7406C11.586 12.5053 11.7815 12.2339 11.9941 11.9814L11.9751 11.9985Z"
        fill="url(#paint1_linear_833_17)"
      />
      <path
        d="M11.994 11.9774C11.641 11.9433 11.288 11.8788 10.9369 11.8788C7.60237 11.8693 4.26402 11.8788 0.935164 11.8788C0.0317784 11.8788 0.0279827 11.8788 0.0279827 10.9469C0.0279827 7.59147 0.0431657 4.23414 0.0146976 0.888202C-0.000485315 0.201174 0.202587 0 0.882023 0C4.29819 0.0328964 7.72067 0.0328964 11.1495 0C11.7663 0 11.9561 0.189787 11.9561 0.793309C11.9333 4.20947 11.9447 7.63892 11.9561 11.0608C11.9561 11.372 11.9808 11.6833 11.994 11.9926V11.9774Z"
        fill="url(#paint2_linear_833_17)"
      />
      <mask
        id="mask0_833_17"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="11"
        width="24"
        height="26"
      >
        <path
          d="M11.9562 12.015C12.2594 12.0684 12.5655 12.1039 12.8729 12.1213H23.0758C23.964 12.1213 23.9659 12.1213 23.9678 13.0076C23.9678 16.3896 23.9508 19.764 23.9811 23.1422C23.9811 23.8008 23.8198 24.0209 23.129 24.0133C19.7128 23.9792 16.2834 23.9982 12.8615 24.0001C11.9505 24.0001 11.9126 24.0342 11.9126 24.9319C11.9126 28.3481 11.8974 31.7775 11.924 35.2013C11.924 35.8314 11.7645 36.0306 11.1117 36.0249C7.66705 35.9946 4.22053 36.0136 0.773997 36.0136C0.555742 36.0136 0.337488 35.9946 0.060399 35.9813C0.060399 35.6681 0.0224416 35.3854 0.0205438 35.1102C0.0205438 31.7756 0.0376246 28.4392 0.00346297 25.1084C-0.0149485 24.7791 0.0402238 24.4499 0.164972 24.1446C0.28972 23.8392 0.48091 23.5656 0.724653 23.3434C4.28126 19.8298 7.82015 16.2998 11.3413 12.7533C11.5767 12.5255 11.7664 12.2504 11.9828 11.9941L11.9562 12.015Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_833_17)">
        <path
          d="M15.0344 24.0117H-2.81885V30.0849H15.0344V24.0117Z"
          fill="url(#paint3_linear_833_17)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_833_17"
          x1="12.0018"
          y1="-0.561465"
          x2="12.0018"
          y2="35.4474"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED0037" />
          <stop offset="1" stopColor="#FF0080" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_833_17"
          x1="11.9977"
          y1="11.7758"
          x2="11.9977"
          y2="35.8068"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED0037" />
          <stop offset="1" stopColor="#FF0080" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_833_17"
          x1="6.00105"
          y1="0.380105"
          x2="6.00105"
          y2="12.3916"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED0037" />
          <stop offset="1" stopColor="#FF0080" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_833_17"
          x1="6.11062"
          y1="24.4688"
          x2="6.11062"
          y2="30.5498"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.46" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
