import {
  MainSection
} from "@/StyledComponents/LayoutStyled";
import ThemeToggleButton from "@/components/DarkMode/ThemeToggleButton";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import LandingSkeletonLoad from "../SkeletonLoading/LandingSkeletonLoad";
// import Header from "./header";
const Footer = dynamic(() => import("./footer"), {
  ssr: false,
});
const Header = dynamic(() => import("./header"), {
  loading: () => ( <LandingSkeletonLoad />
  ),
  ssr: false,
});
export default function Layout({ children }) {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    try {
      // Check for saved theme in localStorage
      const localTheme = localStorage.getItem("theme");
      if (localTheme) {
        setTheme(localTheme);
        document.body.className = localTheme;
      } else {
        // Check user's system preference for dark mode
        const prefersDarkMode = window.matchMedia(
          "(prefers-color-scheme: dark)"
        ).matches;
        const systemTheme = prefersDarkMode ? "dark" : "light";
        setTheme(systemTheme);
        document.body.className = systemTheme;
      }
    } catch (error) {
      //console.log(error);
    }
  }, []);

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);
  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  // const [loadingPage, setLoadingPage] = useState("loading-page");
  // useEffect(() => {
  //   // Load event logic
  //   setLoadingPage("");
  // }, []);
  return (
    <main>
      {/* <GoogleAnalytics gaId="G-DF2MDXQ3CV" /> */}
      {/* Disable Google Analytics on localhost */}
      {/* {process.env.NODE_ENV === "production" && ( */}
      {/* <GoogleAnalytics gaId="G-DF2MDXQ3CV" /> */}
      {/* )} */}
      <div className="min-h-[63.5px] md:min-h-[100.5px] lg:min-h-[100.5px]">      
      <Header theme={theme} />
      </div>

      <ThemeToggleButton theme={theme} toggleTheme={toggleTheme} />
      {/* <RFPModalComponent /> */}
      {/* <ExpandableButton /> */}
      <MainSection>{children}</MainSection>
      <Footer />
    </main>
  );
}
