import React from "react";

export default function HeaderMobileDark() {
  return (
    <svg
      className="header-icon"
      width="25"
      height="25"
      viewBox="0 0 25 18"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M1 1H24"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 9H17"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 17H12"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
