import { HeaderInner, HeaderItems, HeaderLogoLeft, HeaderRightSide, LandingHeaderMain, LayoutHeader, LogoLink, MobileHeaderInner, MobileviewHeader, Talkbutton } from '@/StyledComponents/LayoutStyled';
import { useWindowWidth } from '@/utils/useWindowWidth';
import Link from 'next/link';
import { Container } from 'reactstrap';
import ITServicesMobileLogoSvg from '../Layout/ITServicesMobileLogoSvg';
import HeaderMobileDark from '../svg/HeaderMobileDark';

export default function LandingSkeletonLoad() {
    const IsDesktop = useWindowWidth();

    return (
        <>
            {IsDesktop ?
               
                    <LayoutHeader className='web-view-header'>
                        <LandingHeaderMain>
                            <Container>
                                <HeaderInner>
                                    <HeaderLogoLeft className='w-[147.7px] h-[35] min-w-[147.7px] min-h-[35] max-w-[147.7px] max-h-[35]'>
                                        <Link href="/">IT SERVICES INDIA</Link>
                                    </HeaderLogoLeft>
                                    <div className="flex">
                                    <HeaderRightSide>
                                        <HeaderItems> <span>SERVICES</span></HeaderItems>
                                        <HeaderItems> <span>INDUSTRIES</span></HeaderItems>
                                        <HeaderItems> <span>HIRE</span></HeaderItems>
                                        <HeaderItems> <span>TECHNOLOGIES</span></HeaderItems>
                                        <HeaderItems> <span>COMPANY</span></HeaderItems>
                                    </HeaderRightSide>
                                    <HeaderRightSide>
                                        <div className="w-[174.23px] max-w-[174.23px] min-w-[174.23px] h-[48px] min-h-[48px] max-h-[48px] flex items-center ml-[18px] px-[45.5px] pt-[17px] pb-[16px] text-[12px] leading-[15px] font-semibold uppercase tracking-[2px] rounded-[8px] bg-[#0b0e1e] text-[#FFF] border-none cursor-pointer">
                                            Let’s Talk</div></HeaderRightSide></div>
                                </HeaderInner>
                            </Container>
                        </LandingHeaderMain>
                    </LayoutHeader>
 
                :
              
                    <MobileviewHeader>
                        <MobileHeaderInner>
                            <HeaderMobileDark />
                            <LogoLink>
                                <ITServicesMobileLogoSvg />
                            </LogoLink>
                        </MobileHeaderInner>

                        <Talkbutton>
                            Let’s Talk
                        </Talkbutton>
                    </MobileviewHeader>
                    
      
            }
        </>
    )
}
